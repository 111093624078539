// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-width {
  max-width: 1280px !important;
  width: 148vh !important;
  margin: 0 auto !important;
  padding: 0 10px 0 10px !important;
}
`, "",{"version":3,"sources":["webpack://./src/AdminDashboard.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,uBAAuB;EACvB,yBAAyB;EACzB,iCAAiC;AACnC","sourcesContent":[".custom-width {\n  max-width: 1280px !important;\n  width: 148vh !important;\n  margin: 0 auto !important;\n  padding: 0 10px 0 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
